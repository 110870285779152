import React, { useRef } from "react";
import Slider from "react-slick";
import { GrFormPreviousLink } from "react-icons/gr";
import { GrFormNextLink } from "react-icons/gr";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

const Sliders = () => {
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  const settings = {
    // dots: true,
    infinite: true,
    arrows: true,

    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="slider-container">
      <div className="category-btn-prev-next">
        <GrFormPreviousLink
          onClick={previous}
          className="category-prev"
          size={50}
        />
        <GrFormNextLink onClick={next} className="category-next" size={50} />
      </div>
      <Slider
        {...settings}
        ref={(slider) => {
          sliderRef = slider;
        }}
      >
        <div className="wrapper">
          <img src="/assets/images/PussyEater.jpg" alt="slider-img1" />
          <h3>PussyEater</h3>
        </div>
        <div className="wrapper">
          <img src="/assets/images/NakedCat.jpg" alt="slider-img1" />
          <h3>NakedCat</h3>
        </div>
        <div className="wrapper">
          <img src="/assets/images/AndrewCate.jpg" alt="slider-img3" />
          <h3>AndrewCate</h3>
        </div>
        <div className="wrapper">
          <img src="/assets/images/WastedCat.jpg" alt="slider-img2" />
          <h3>WastedCat</h3>
        </div>
      </Slider>
    </div>
  );
};

export default Sliders;
