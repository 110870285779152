import React from "react";

const Footer = () => {
  return (
    <footer>
      <p>©2024 . All Rights Reserved.</p>
      <div className="social-con">
        <a href="https://www.facebook.com/" target="_blank">
          <img src="/assets/images/facebook.svg" alt="facebook" />
        </a>
        <a href="https://twitter.com/" target="_blank">
          <img src="/assets/images/twitter.svg" alt="twitter" />
        </a>
        <a
          href="https://www.instagram.com/accounts/login/?hl=en"
          target="_blank"
        >
          <img src="/assets/images/instagram.svg" alt="instagram" />
        </a>
        <a href="https://discord.com/" target="_blank">
          <img src="/assets/images/discord.svg" alt="discord" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
