import React, { useEffect, useState } from "react";
import { FaTelegramPlane, FaTwitter } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";

const Header = () => {
  const [activeItem, setActiveItem] = useState("Home");
  const [isActive, setIsActive] = useState(false);
  const [name, setName] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const presaleValue = searchParams.get("sec-8");

  // Additional logging for debugging
  // console.log("Location:", location);
  // console.log("Search Parameters:", searchParams.toString());
  // console.log("FAQ Value:", presaleValue);

  useEffect(() => {
    if (searchParams.get("scrollTo")) {
      navigate("/");
      document
        .getElementById(searchParams.get("scrollTo"))
        ?.scrollIntoView?.({ block: "start", behavior: "smooth" });
    }
    document
      .getElementById(name)
      ?.scrollIntoView?.({ block: "start", behavior: "smooth" });

    setName("");
  }, [searchParams.get("scrollTo"), name]);

  const openPdfInNewTab = () => {
    window.open("/assets/doc/whitepaper.pdf", "_blank");
};

  return (
    <React.Fragment>
      <header>
        <div className="left"></div>
        <div className="right">
          {/* <ul>
            <li
              className={activeItem === "Home" ? "active" : ""}
              onClick={() => setActiveItem("Home")}
            >
              Home
            </li>
            <li>Token Economics</li>
            <li>Fund Allocation</li>
            <li>Roadmap</li>
            <li>Team</li>
            <li>About us</li>
            <li>Faqs</li>
            <li>Blog</li>
          </ul> */}
          <ul>
            <Link to="/">
              <li
                className={activeItem === "Home" ? "active" : ""}
                onClick={() => setActiveItem("Home")}
              >
                HOME
              </li>
            </Link>
            <Link to="/?scrollTo=sec-4">
              <li>TOKEN ECONOMICS</li>
            </Link>
            {/* <Link to="/fundallocation">
              <li>FUND ALLOCATION</li>
            </Link> */}
            <Link to="/?scrollTo=sec-5">
              <li>ROADMAP</li>
            </Link>
            <Link to="/?scrollTo=sec-6">
              <li>TEAM</li>
            </Link>
            <Link to="/?scrollTo=sec-7">
              <li>ABOUT US</li>
            </Link>
            <Link to="/?scrollTo=sec-8">
              <li>FAQS</li>
            </Link>
            <Link onClick={openPdfInNewTab}>
              <li>WHITEPAPER</li>
            </Link>
          </ul>
        </div>
        {/* <div className="right"></div> */}

        {/* <MOBILE */}
        <div className="hamburger-mobile">
          <ul>
            <li className="hamburger" onClick={() => setIsActive(!isActive)}>
              <GiHamburgerMenu />
            </li>
          </ul>
        </div>
        <div
          className={`mobile-nav ${isActive ? "active" : ""}`}
          id="mobile-hambargur"
        >
          <div className="one">
            <div className="one-left">
              <a href="/">
                {/* <img
                  src="./assets/logo/mmtr-logo.jpg"
                  alt="logo"
                  style={{ width: "65px", borderRadius: "100%" }}
                /> */}
              </a>
            </div>
            <div
              className={`one-right ${isActive ? "active" : ""}`}
              onClick={() => setIsActive(!isActive)}
            >
              <RxCross2 />
            </div>
          </div>

          <div className="two" id="after-login-mobile">
            <ul>
              <Link
                to="/"
                onClick={() => {
                  setIsActive(false);
                }}
              >
                <li>HOME</li>
              </Link>
              <Link
                to="/?scrollTo=sec-4"
                onClick={() => {
                  setIsActive(false);
                }}
              >
                <li>TOKEN ECONOMICS</li>
              </Link>
              {/* <Link
                to="/fundallocation"
                onClick={() => {
                  setIsActive(false);
                }}
              >
                <li>FUND ALLOCATION</li>
              </Link> */}
              <Link
                to="/?scrollTo=sec-5"
                onClick={() => {
                  setIsActive(false);
                }}
              >
                <li>ROADMAP</li>
              </Link>
              <Link
                to="/?scrollTo=sec-6"
                onClick={() => {
                  setIsActive(false);
                }}
              >
                <li>TEAM</li>
              </Link>
              <Link
                to="/?scrollTo=sec-7"
                onClick={() => {
                  setIsActive(false);
                }}
              >
                <li>ABOUT US</li>
              </Link>
              <Link
                to="/?scrollTo=sec-8"
                onClick={() => {
                  setIsActive(false);
                }}
              >
                <li>FAQS</li>
              </Link>
              {/* <Link to="/blog">
                <li>BLOG</li>
              </Link> */}
            </ul>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
