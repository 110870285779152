import React, { createContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { WalletDisconnectButton, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useConnection, useAnchorWallet, useWallet } from "@solana/wallet-adapter-react";
import { Program, AnchorProvider, utils, BN, web3 } from "@coral-xyz/anchor";
import { clusterApiUrl, Connection, Keypair, PublicKey, LAMPORTS_PER_SOL, sendAndConfirmTransaction, Transaction, SYSVAR_RENT_PUBKEY } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID, createTransferInstruction, Token, getAccount, createAssociatedTokenAccount, createAssociatedTokenAccountInstruction, createAccount, getAssociatedTokenAddress, getOrCreateAssociatedTokenAccount, ASSOCIATED_TOKEN_PROGRAM_ID, transfer } from '@solana/spl-token';
import idl from "./idl.json";
import * as buffer from "buffer";
import { ASSOCIATED_PROGRAM_ID } from "@coral-xyz/anchor/dist/cjs/utils/token";
import { Buffer } from "buffer";
window.Buffer = buffer.Buffer;
const utf8 = utils.bytes.utf8


// import { TOKEN_PROGRAM_ID, Token } from "@solana/spl-token";

// import { Buffer } from 'node:buffer';

// import {BN, Program, utils, web3 } from '@project-serum/anchor';


export const Store = createContext();

export const StoreProvider = ({ children }) => {

  const [loader, setLoader] = useState(true);
  const [user, setIsUser] = useState(false);


  const [isInitiliseStatePDA, setIsInitiliseStatePDA] = useState(true);
  const [isInitiliseTokenPDA, setIsInitiliseTokenPDA] = useState(true);


  const [isPresaleStart, setIsPresaleStart] = useState(false);


  // const connection = new Connection(
  //   "http://127.0.0.1:8899",
  // );

  const { connection } = useConnection();

  // const connection = new Connection(clusterApiUrl("devnet"), {
  //   commitment: "confirmed",
  // })

  const { publicKey, signTransaction, sendTransaction } = useWallet();


  const [toatlSoldTokens, setTotalsoldTokens] = useState(0);
  const [totalRaisedSol, setTotalRaisedSol] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(0);
  const [purchasedToken, setPurchasedTokens] = useState(0);

  const [connected, setConnected] = useState(false);
  const [pubKey, setPubKey] = useState("");

  const anchorWallet = useAnchorWallet();

  const anchorProvider = new AnchorProvider(connection, anchorWallet, {});

  const program = new Program(idl, idl.metadata.address, anchorProvider);


  const tokenPRESALE = new PublicKey(process.env.REACT_APP_PRESALE_TOKEN);
  const ownerKey = new PublicKey(process.env.REACT_APP_OWNER_WALLET);

  // console.log(tokenPRESALE?.toBase58(), ownerKey?.toBase58(), "tokenPRESALEownerKey")

  const getProvider = () => {
    if ("phantom" in window) {
      const provider = window.phantom?.solana;
      if (provider?.isPhantom) {
        return provider;
      }
    }
    // window.open("https://phantom.app/", "_blank");
  };

  const connectWallet = async () => {
    // console.log("connectWallet");
    try {
      const provider = getProvider();
      const resp = await provider.request({ method: "connect" });
      // console.log(resp, "changessssssssssssssssssssssssssssssss");
      let walletPublicKey = resp?.publicKey?.toString();
      setPubKey(
        `${walletPublicKey?.slice(0, 7)}....${walletPublicKey?.slice(-7)}`
      );
      setConnected(true);
    } catch (err) {
      console.error(err);
    }
  };

  const disconnect = async () => {
    try {
      const provider = getProvider();

      provider.request({ method: "disconnect" });

      setConnected(false);
      //   toast.success("Wallet disconnected");
    } catch (err) {
      console.error(err);
    }
  };

  const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID = new PublicKey(
    'ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL',
  );

  function findAssociatedTokenAddress(walletAddress, tokenMintAddress) {
    return PublicKey.findProgramAddressSync(
      [
        walletAddress.toBuffer(),
        TOKEN_PROGRAM_ID.toBuffer(),
        tokenMintAddress.toBuffer(),
      ],
      SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
    )[0];
  }

  // //////////////////////////////////////////////////////////////////////////////////////////////
  // //////////////////////////////////////////////////////////////////////////////////////////////
  // /////////////////////////////////// Presale Get Contract Section /////////////////////////////
  // //////////////////////////////////////////////////////////////////////////////////////////////
  // //////////////////////////////////////////////////////////////////////////////////////////////
  // //////////////////////////////////////////////////////////////////////////////////////////////

  let adminPRESALEWallet = findAssociatedTokenAddress(ownerKey, tokenPRESALE);

  const getUserDataValue = async () => {


    const [userStatsPDA, bump_state] = await PublicKey.findProgramAddress(
      [
        ownerKey.toBuffer(),
        adminPRESALEWallet.toBuffer(),
        utf8.encode("PAJAMAS_PRESALE"),
      ],
      program.programId
    );

    if (await anchorProvider.connection.getAccountInfo(userStatsPDA) === null) {
      setIsInitiliseStatePDA(false);
      setIsPresaleStart(false);
      return
    } else {
      try {
        // console.log(userStatsPDA, "userStatsPDAuserStatsPDA")
        const Campaign = await program.account.state.fetch(userStatsPDA);
        // console.log(Campaign, "CampaignCampaignCampaign")
        // console.log("Campaign:", Campaign);
        // console.log("Admin Public Key:", Campaign.admin?.toBase58());
        // console.log("total Token :", Campaign.totalSoldToken.toString());
        // console.log("Raised Amount :", Campaign.totalRaisedSol.toString());
        // console.log("Price :", Campaign.price.toNumber() / 1e9);
        // console.log("Presale Token :", Campaign.presaleTokens.toString());
        // console.log("is start presale:", Campaign.isStart.toString());
        // console.log("Create Presale Done");
        if (Campaign?.isStart) { ///TODO:: Change here  Campaign?.isStart
          setIsPresaleStart(true);
          let stakeBalance = await anchorProvider.connection.getBalance(anchorWallet?.publicKey);
          // console.log(`User Balance After Buy Token: ${stakeBalance / LAMPORTS_PER_SOL} SOL`);
          setTotalsoldTokens(Campaign?.totalSoldToken?.toNumber() / 1e9);
          setTokenPrice(Campaign?.price?.toNumber() / 1e9)
          // setTokenPrice(Campaign?.price?.toNumber())
          setTotalRaisedSol(Campaign?.totalRaisedSol / LAMPORTS_PER_SOL);
          if (user) {
            const [userAccount, _2] = PublicKey.findProgramAddressSync(
              [
                Buffer.from("PRESALE_PAJAMA_USER"), anchorWallet?.publicKey.toBuffer()
              ],
              program.programId
            );

            let userdata = await program.account.userdata.fetch(userAccount);
            // console.log("User Data Public Key (Buyer address):", userdata?.userAddress?.toBase58());
            // console.log("User Purchased Amount :", userdata?.purchaseAmount?.toNumber() / 1e9);

            setPurchasedTokens(userdata?.purchaseAmount?.toNumber() / 1e9)
          }
        } else {
          setIsPresaleStart(false);
          setLoader(false)
        }
        setLoader(false)
      } catch (error) {
        setLoader(false)
        setTotalsoldTokens(0);
        setTotalRaisedSol(0);
        setPurchasedTokens(0)
      }
    }

  }

  const getAdminSectionValues = async () => {

    let adminPRESALEWallet = findAssociatedTokenAddress(ownerKey, tokenPRESALE);

    const [user_pda_state, bump_state] = await PublicKey.findProgramAddress(
      [ownerKey.toBuffer(), adminPRESALEWallet.toBuffer(), utf8.encode("PAJAMAS_PRESALE")],
      program.programId
    );
    if (await anchorProvider.connection.getAccountInfo(user_pda_state) === null) {
      setIsInitiliseStatePDA(false);
    }
    /// token PDA 
    const [usertokenpda, bump_token] = await PublicKey.findProgramAddress(
      [ownerKey.toBuffer(), adminPRESALEWallet.toBuffer()],
      program.programId
    );

    if (await anchorProvider.connection.getAccountInfo(usertokenpda) === null) {
      setIsInitiliseTokenPDA(false)
    }
    setLoader(false)
  }

  const getIsUser = async () => {

    if (publicKey) {
      const [userAccount, _2] = PublicKey.findProgramAddressSync(
        [
          Buffer.from("PRESALE_PAJAMA_USER"), anchorWallet?.publicKey.toBuffer()
        ],
        program.programId
      );

      if (await anchorProvider.connection.getAccountInfo(userAccount) !== null) {
        setIsUser(true);
      }
      else {
        setIsUser(false);
      }
    }
    setLoader(false)
  }
  // console.log(isInitiliseStatePDA, isPresaleStart, "isInitiliseStatePDAisPresaleStart")

  // //////////////////////////////////////////////////////////////////////////////////////////////
  // //////////////////////////////////////////////////////////////////////////////////////////////
  // /////////////////////////////////// Presale Admin Section /////////////////////////////////
  // //////////////////////////////////////////////////////////////////////////////////////////////
  // //////////////////////////////////////////////////////////////////////////////////////////////
  // //////////////////////////////////////////////////////////////////////////////////////////////

  // //step 1
  const createPreSaleStart = async () => {

    try {
      setLoader(true);
      // console.log(anchorWallet?.publicKey?.toBase58(),"anchorWallet.publicKey")
      // console.log(adminPRESALEWallet?.toBase58(),"anchorWallet.publicKey")

      const [userStatsPDA, bump_state] = await PublicKey.findProgramAddress(
        [
          anchorWallet.publicKey.toBuffer(),
          adminPRESALEWallet.toBuffer(),
          utf8.encode("PAJAMAS_PRESALE"),
        ],
        program.programId
      );

      await program.methods.startPresale(bump_state).accounts({
        statepda: userStatsPDA,
        owner: anchorWallet.publicKey,
        depositTokenAccount: adminPRESALEWallet,
        systemProgram: web3.SystemProgram.programId,
      }).rpc();

      const presaleData = await program.account.state.fetch(userStatsPDA);
      // console.log("Campaign:", presaleData);
      // console.log("Admin Public Key:", presaleData.admin?.toBase58());
      // console.log("total Token :", presaleData.totalSoldToken.toString());
      // console.log("Raised Amount :", presaleData.totalRaisedSol.toString());
      // console.log("Price :", presaleData.price.toNumber() / 1e9);
      // console.log("Presale Token :", presaleData.presaleTokens.toString());
      // console.log("is start presale:", presaleData.isStart.toString());
      // console.log("Create Presale Done");
      getAdminSectionValues();
      getUserDataValue();
      getIsUser()

      setLoader(false);
      toast.success("Sucessfully Created");
      return true
    } catch (error) {
      setLoader(false);
      console.log(error, "error")
      toast.error("Transaction Failed");
      return false
    }
  } 

  //step 2
  const initializeATA = async () => {
    try {
      setLoader(true)

      let adminPRESALEWallet = findAssociatedTokenAddress(ownerKey, tokenPRESALE);

      const [user_pda_state, bump_state] = await PublicKey.findProgramAddress(
        [anchorWallet.publicKey.toBuffer(), adminPRESALEWallet.toBuffer(), utf8.encode("PAJAMAS_PRESALE")],
        program.programId
      );

      const [user_pda_state2, bump_state2] = await PublicKey.findProgramAddress(
        [anchorWallet.publicKey.toBuffer(), adminPRESALEWallet.toBuffer()],
        program.programId
      );


      // console.log(user_pda_state2, user_pda_state, "user_pda_state2user_pda_state2user_pda_state2")

      const tx3 = await program.methods.initialisetokenpda(bump_state2).accounts({
        tokenpda: user_pda_state2,
        statepda: user_pda_state,
        mint: tokenPRESALE,
        owner: anchorWallet.publicKey,
        depositTokenAccount: adminPRESALEWallet,
        systemProgram: web3.SystemProgram.programId,
        rent: SYSVAR_RENT_PUBKEY,
        tokenProgram: TOKEN_PROGRAM_ID,
      }).rpc();

      getAdminSectionValues();
      getUserDataValue();
      getIsUser()
      setLoader(false)
      toast.success("Sucessfully Created");
      return true
    } catch (error) {
      setLoader(false)
      console.log(error)
      toast.error("Transaction Failed");
      return false
    }
  }




  
  const saleStart = async () => {

    try {
      setLoader(true);

      const [userStatsPDA, bump_state1] = await PublicKey.findProgramAddress(
        [
          ownerKey.toBuffer(),
          adminPRESALEWallet.toBuffer(),
          utf8.encode("PAJAMAS_PRESALE"),
        ],
        program.programId
      );

      const tx = await program.methods.sellStart().accounts({
        statepda: userStatsPDA,
        owner: anchorWallet?.publicKey
      }).rpc();

      const presaleData = await program.account.state.fetch(userStatsPDA);
      // console.log("is start presale:", presaleData.isStart.toString());
      // console.log("Presale Start Done");
      getUserDataValue();
      getAdminSectionValues();
      getIsUser()
      setLoader(false);
      toast.success("Sucessfully Started");
    } catch (error) {
      setLoader(false);
      console.log(error, "error")
      toast.error("Transaction Failed");
    }
  }

  const saleStop = async () => {

    try {
      setLoader(true);

      const [userStatsPDA, bump_state1] = await PublicKey.findProgramAddress(
        [
          ownerKey.toBuffer(),
          adminPRESALEWallet.toBuffer(),
          utf8.encode("PAJAMAS_PRESALE"),
        ],
        program.programId
      );

      const tx = await program.methods.sellStop().accounts({
        statepda: userStatsPDA,
        owner: anchorWallet?.publicKey
      }).rpc();

      const presaleData = await program.account.state.fetch(userStatsPDA);
      // console.log("is start presale:", presaleData.isStart.toString());
      // console.log("Presale Start Done");
      getUserDataValue();
      getAdminSectionValues();
      getIsUser()
      setLoader(false);
      toast.success("Sucessfully Started");
    } catch (error) {
      setLoader(false);
      console.log(error, "error")
      toast.error("Transaction Failed");
    }
  }

  const depositPresaleTokens = async () => {
    try {
      setLoader(true);
      let adminPRESALEWallet = findAssociatedTokenAddress(ownerKey, tokenPRESALE);

      const [user_pda_state, bump_state] = await PublicKey.findProgramAddress(
        [anchorWallet.publicKey.toBuffer(), adminPRESALEWallet.toBuffer(), utf8.encode("PAJAMAS_PRESALE")],
        program.programId
      );

      const [user_pda_state2, bump_state2] = await PublicKey.findProgramAddress(
        [anchorWallet.publicKey.toBuffer(), adminPRESALEWallet.toBuffer()],
        program.programId
      );

      // console.log(pdaPresaleAccount?.toBase58(), "pdaPresaleAccountpdaPresaleAccountpdaPresaleAccount")

      let tok = "300000000000000000" //presale 30%
      let tokens = new BN(tok)
      const tx3 = await program.methods.depositPresaleToken(bump_state, bump_state2, tokens).accounts({
        tokenpda: user_pda_state2,
        statepda: user_pda_state,
        mint: tokenPRESALE,
        owner: anchorWallet.publicKey,
        depositTokenAccount: adminPRESALEWallet,
        systemProgram: web3.SystemProgram.programId,
        rent: SYSVAR_RENT_PUBKEY,
        tokenProgram: TOKEN_PROGRAM_ID,
      }).rpc();

      setLoader(false);
      getAdminSectionValues();
      getUserDataValue();
      getIsUser()
      toast.success("Sucessfully Deposit");
    } catch (error) {
      setLoader(false);
      console.log(error)
      toast.error("Transaction Failed");
    }
  }

  // //////////////////////////////////////////////////////////////////////////////////////////////
  // //////////////////////////////////////////////////////////////////////////////////////////////
  // /////////////////////////////////// Presale Contract Section /////////////////////////////////
  // //////////////////////////////////////////////////////////////////////////////////////////////
  // //////////////////////////////////////////////////////////////////////////////////////////////
  // //////////////////////////////////////////////////////////////////////////////////////////////

  const participateUser = async () => {
    try {
      setLoader(true)

      const [userAccount, _2] = PublicKey.findProgramAddressSync(
        [
          Buffer.from("PRESALE_PAJAMA_USER"), anchorWallet.publicKey.toBuffer()
        ],
        program.programId
      );

      const tx = await program.methods.initialize().accounts({
        userAccount,
        buyer: anchorWallet?.publicKey,
        systemProgram: web3.SystemProgram.programId,
      }).rpc();
      getAdminSectionValues();
      getUserDataValue();
      getIsUser()
      setLoader(false)
      toast.success("Sucessfully Participated");
    } catch (error) {
      setLoader(false)
      console.log(error)
      toast.error("Transaction Failed");
    }

  }

  const buyWithSol = async (amount) => {


    if (amount == 0) {
      return toast.error("please Input Amount")
    }
    setLoader(true)


    const [userStatsPDA, bump_state] = await PublicKey.findProgramAddress(
      [
        ownerKey.toBuffer(),
        adminPRESALEWallet.toBuffer(),
        utf8.encode("PAJAMAS_PRESALE"),
      ],
      program.programId
    );

    const [user_pda_state2, bump_state2] = await PublicKey.findProgramAddress(
      [ownerKey.toBuffer(), adminPRESALEWallet.toBuffer()],
      program.programId
    );

    const [userAccount, _2] = PublicKey.findProgramAddressSync(
      [
        Buffer.from("PRESALE_PAJAMA_USER"), anchorWallet.publicKey.toBuffer()
      ],
      program.programId
    );

    let tokenAmount = +amount * 10 ** 9;

    const fromTokenAddress = await getAssociatedTokenAddress(tokenPRESALE, anchorWallet.publicKey)

   
    //////////////////////////////////////////////////////////////////////////////////////
    let isuser;
    try {
      let account = await getAccount(connection, fromTokenAddress, "conformed", TOKEN_PROGRAM_ID);
      // console.log(account, "accountaccountaccountaccount")
      isuser = true;
    } catch (error) {
      isuser = false;
      // console.log("falseeeeeeee")
    }


    if (isuser) {
      try {
        const userPreSellTokenAccount = await getOrCreateAssociatedTokenAccount(
          connection,
          anchorWallet,
          tokenPRESALE,
          anchorWallet?.publicKey
        );

        await program.methods.buyWithSol(bump_state, bump_state2, new BN(tokenAmount)).accounts({
          tokenpda: user_pda_state2,
          statepda: userStatsPDA,
          userAccount: userAccount,
          walletToDepositTo: userPreSellTokenAccount.address,
          sender: ownerKey,
          depositTokenAccount: adminPRESALEWallet,
          reciever: anchorWallet?.publicKey,
          systemProgram: web3.SystemProgram.programId,
          rent: SYSVAR_RENT_PUBKEY,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          tokenProgram: TOKEN_PROGRAM_ID,
        }).rpc();
        toast.success("You have Successfully Purchased the Token!");
      } catch (error) {
        console.log(error)
        toast.error("Failed");
      }
    }

    else {
      try {
        const associatedAccount = await getAssociatedTokenAddress(
          tokenPRESALE,
          anchorWallet.publicKey
        )

        // console.log(associatedAccount?.toBase58(), "createToken")

        const createToken = new Transaction().add(
          createAssociatedTokenAccountInstruction(
            anchorWallet.publicKey,
            associatedAccount,
            anchorWallet.publicKey,
            tokenPRESALE
          )
        );

        const signature = await sendTransaction(createToken, connection);

        await connection.confirmTransaction(signature, 'processed');

        const userPreSellTokenAccount = await getOrCreateAssociatedTokenAccount(
          connection,
          anchorWallet,
          tokenPRESALE,
          anchorWallet?.publicKey
        );

        await program.methods.buyWithSol(bump_state, bump_state2, new BN(tokenAmount)).accounts({
          tokenpda: user_pda_state2,
          statepda: userStatsPDA,
          userAccount: userAccount,
          walletToDepositTo: userPreSellTokenAccount.address,
          sender: ownerKey,
          depositTokenAccount: adminPRESALEWallet,
          reciever: anchorWallet?.publicKey,
          systemProgram: web3.SystemProgram.programId,
          rent: SYSVAR_RENT_PUBKEY,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          tokenProgram: TOKEN_PROGRAM_ID,
        }).rpc();
        toast.success("You have Successfully Purchased the Token!");
      } catch (error) {
        console.log(error)
        toast.error("Failed");
      }
    }






    // console.log(createToken, "createToken")

    // const userPreSellTokenAccount = await getOrCreateAssociatedTokenAccount(
    //   connection,
    //   anchorWallet,
    //   tokenPRESALE,
    //   anchorWallet?.publicKey
    // );

    // const userPreSellTokenAccount = await token.getAssociatedTokenAddress(
    //   ASSOCIATED_TOKEN_PROGRAM_ID,
    //   TOKEN_PROGRAM_ID, // Token Extension Program ID
    //   tokenPRESALE, // Mint Account address
    //   anchorWallet?.publicKey
    // );

    // console.log(userPreSellTokenAccount, "userPreSellTokenAccount")
    /////////////////////////////////////////////////////////////////////////////////////////////////////

    // let userPRESALEWallet = findAssociatedTokenAddress(anchorWallet?.publicKey, tokenPRESALE);
    // console.log(userPRESALEWallet)

    // await program.methods.buyWithSol(bump_state, bump_state2, new BN(tokenAmount)).accounts({
    //   tokenpda: user_pda_state2,
    //   statepda: userStatsPDA,
    //   userAccount: userAccount,
    //   walletToDepositTo: userPreSellTokenAccount.address,
    //   sender: ownerKey,
    //   depositTokenAccount: adminPRESALEWallet,
    //   reciever: anchorWallet?.publicKey,
    //   systemProgram: web3.SystemProgram.programId,
    //   rent: SYSVAR_RENT_PUBKEY,
    //   associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
    //   tokenProgram: TOKEN_PROGRAM_ID,
    // }).rpc();
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////


    getAdminSectionValues();
    getUserDataValue();
    getIsUser()
    setLoader(false);
  }

  // const airdropTokens = async (recipientAddresses, amount) => {
  //   try {
  //     setLoader(true)
  //     if (!recipientAddresses || recipientAddresses.length === 0 || !amount) {
  //       return toast.error("Please provide recipient addresses and amount");
  //     }

  //     if (!anchorWallet) {
  //       return toast.error("Wallet not connected");
  //     }

  //     const instructions = [];

  //     for (let i = 0; i < recipientAddresses.length; i++) {
  //       // console.log(recipientAddresses[i], "recipientAddresses[i]recipientAddresses[i]")

  //       const recipientPublicKey = new PublicKey(recipientAddresses[i]);
  //       // const recipientPublicKey = new PublicKey("EavYHxJeNhcfqP5C5xMa1enPuBL5YywXcyEv7U9Hrkgy");
  //       // const recipientPublicKey = new PublicKey("GxWcULvZABsT1ca9bqwPPJRamzoL5oM4MJ3pKHafFSpv");



  //       let adminPRESALEWallet = findAssociatedTokenAddress(ownerKey, tokenPRESALE);

  //       // let userPRESALEWallet = findAssociatedTokenAddress(recipientPublicKey, tokenPRESALE);

  //       // const recipientPreSellTokenAccount = await getOrCreateAssociatedTokenAccount(
  //       //   connection,
  //       //   anchorWallet,
  //       //   tokenPRESALE,
  //       //   anchorWallet?.publicKey,
  //       //   false,
  //       //   TOKEN_PROGRAM_ID,
  //       //   ASSOCIATED_TOKEN_PROGRAM_ID
  //       // );
  //       // const connections = new web3.Connection(web3.clusterApiUrl("devnet"), {
  //       //   commitment: "confirmed",
  //       // });

  //       // const fromATA = await getOrCreateAssociatedTokenAccount(
  //       //   connection,
  //       //   anchorWallet, // Any keypair can be used here as the receiver doesn't sign
  //       //   tokenPRESALE,
  //       //   recipientPublicKey, // Replace with receiver's address
  //       //   signTransaction
  //       // );

  //       // const receiverAta = await getOrCreateAssociatedTokenAccount(
  //       //   connection,
  //       //   anchorWallet, // Any keypair can be used here as the receiver doesn't sign
  //       //   tokenPRESALE,
  //       //   recipientPublicKey, // Replace with receiver's address
  //       //   signTransaction
  //       // );

  //       // const connections = new Connection(clusterApiUrl("devnet"), "confirmed");


  //       // let userTokenAccount = await getOrCreateAssociatedTokenAccount(
  //       //   connections,
  //       //   anchorWallet,
  //       //   tokenPRESALE,
  //       //   ownerKey
  //       // );
  //       // const tokenPRESALE = new PublicKey(process.env.REACT_APP_PRESALE_TOKEN);


  //       /////////////////// On Private Key ////////////////////////////
  //       let keys = process.env.REACT_APP_ACCOUNT_KEY;

  //       let privateKey = Uint8Array.from(JSON.parse(`${keys}`));

  //       // Load the keypair from the private key
  //       const keypair = Keypair.fromSecretKey(privateKey);

  //       const receiverAta = await getOrCreateAssociatedTokenAccount(
  //         connection,
  //         keypair, // Any keypair can be used here as the receiver doesn't sign
  //         tokenPRESALE,
  //         recipientPublicKey, // Replace with receiver's address
  //       );

  //       /////////////////// From wallet ////////////////////////////

  //       // const associatedAccount = await getAssociatedTokenAddress(
  //       //   tokenPRESALE,
  //       //   recipientPublicKey
  //       // )
  //       // // // const createToken = new Transaction().add(
  //       //  const approve =  createAssociatedTokenAccountInstruction(
  //       //   recipientPublicKey,
  //       //     associatedAccount,
  //       //     recipientPublicKey,
  //       //     tokenPRESALE
  //       //   )

  //       // instructions.push(approve);
        
  //       /////////////////// ///////////////////////////////////////////////
        
        
  //       const adjustedAmount = amount * Math.pow(10, 9);

  //       const transferInstruction = createTransferInstruction(
  //         adminPRESALEWallet,
  //         receiverAta.address,
  //         anchorWallet.publicKey,
  //         new BN(adjustedAmount),
  //         [],
  //         TOKEN_PROGRAM_ID,
  //       );
  //       instructions.push(transferInstruction);
  //     }

  //     // console.log(amount, "Amocunt", instructions, "Instructions")
  //     const transaction = new Transaction().add(...instructions);

  //     const { blockhash } = await connection.getRecentBlockhash();
  //     transaction.recentBlockhash = blockhash;
  //     transaction.feePayer = anchorWallet.publicKey;

  //     const signedTransaction = await anchorWallet.signTransaction(transaction);

  //     const txid = await connection.sendRawTransaction(signedTransaction.serialize());

  //     await connection.confirmTransaction(txid);

  //     toast.success("Airdrop successful!");
  //     setLoader(false)
  //   } catch (error) {
  //     setLoader(false)
  //     console.error(error, "error");
  //     toast.error("Airdrop failed");
  //   }
  // };




  // const handlePayment = async () => {
  //   try {


  //     const recipientAddress = new PublicKey("37FJFw5u17U9JGEgwMCxvFmcCLusByvXU8QbP6VJQhKW");

  //     const tokenPRESALE = new PublicKey("4bSKbTnv3UY7RemaChKC4Yt6XrGmVek2E5LJdGLdNDZB");


  //     // console.log(`Create Token Account: ${fromTokenAccount?.address?.toBase58()}`);

  //     // let sourceTokenAccount = await getOrCreateAssociatedTokenAccount(
  //     //   connection,
  //     //   anchorWallet,
  //     //   tokenPRESALE,
  //     //   anchorWallet.publicKey
  //     // )


  //     // const info = await connection.getAccountInfo(anchorWallet.publicKey);
  //     // console.log(info,"infoinfo")

  //     //   const associatedToken = await getAssociatedTokenAddress(
  //     //     tokenPRESALE,
  //     //     anchorWallet.publicKey,
  //     //     false,
  //     //     TOKEN_PROGRAM_ID,
  //     //     ASSOCIATED_TOKEN_PROGRAM_ID
  //     // );

  //     // console.log(associatedToken?.toBase58(),"associatedToken")
  //     // const recipientAddresssss = new PublicKey("12RLCaQw639oasdTWmXEgGqDsf78DaZBFEE2iRY3jCt9");



  //     // console.log(info,"info")

  //     // let account = await getAccount(connection, associatedToken, "confirmed", TOKEN_PROGRAM_ID);


  //     // console.log(account,"account")
  //     // console.log(sourceTokenAccount,"sourceTokenAccountsourceTokenAccountsourceTokenAccount")

  //     //     // Create Token Account for Playground wallet
  //     //   const sourceTokenAccount = await createAccount(
  //     //     connection,
  //     //     anchorWallet, // Payer to create Token Account
  //     //     tokenPRESALE, // Mint Account address
  //     //     anchorWallet.publicKey, // Token Account owner
  //     //     undefined, // Optional keypair, default to Associated Token Account
  //     //     undefined, // Confirmation options
  //     //     TOKEN_PROGRAM_ID // Token Extension Program ID
  //     // );


  //     // let userTokenAccount = await getOrCreateAssociatedTokenAccount(
  //     //   connection,
  //     //   anchorWallet,
  //     //   tokenPRESALE,
  //     //   recipientAddress
  //     // );
  //     // console.log(token, "token")
  //     // console.log(TOKEN_PROGRAM_ID?.toBase58(), "TOKEN_PROGRAM_IDTOKEN_PROGRAM_ID")

  //     // const sourceTokenAccount = await token.createAccount(
  //     //   connection,
  //     //   anchorWallet.publicKey, // Payer to create Token Account
  //     //   tokenPRESALE, // Mint Account address
  //     //   recipientAddress, // Token Account owner
  //     //   undefined, // Optional keypair, default to Associated Token Account
  //     //   undefined, // Confirmation options
  //     //   TOKEN_PROGRAM_ID, // Token Extension Program ID
  //     // );

  //     // const sourceTokenAccount = await token.getAssociatedTokenAddress(
  //     //   ASSOCIATED_TOKEN_PROGRAM_ID,
  //     //   TOKEN_PROGRAM_ID, // Token Extension Program ID
  //     //   tokenPRESALE, // Mint Account address
  //     //   recipientAddress
  //     // );


  //     // const sourceTokenAccount = await token.createAccount(tokenPRESALE,recipientAddress);

  //     // await token.getAccountInfo(sourceTokenAccount)


  //     // let userTokenAccount = await token.createAssociatedTokenAccount(
  //     //   connection,
  //     //   anchorWallet,
  //     //   tokenPRESALE,
  //     //   anchorWallet

  //     // );

  //     // console.log(userTokenAccount?.address.toBase58(), "userTokenAccount")

  //     // const associatedTokenFrom = await getAssociatedTokenAddress(
  //     //   tokenPRESALE,
  //     //   ownerKey
  //     // );
  //     // console.log("cheek2")
  //     // // /getOrCreateAssociatedTokenAccount
  //     // const instructions = [];

  //     // const fromAccount = await getAssociatedTokenAddress(tokenPRESALE, associatedTokenFrom);

  //     // console.log("cheek3")
  //     // const associatedTokenTo = await getAssociatedTokenAddress(
  //     //   tokenPRESALE,
  //     //   recipientAddress
  //     // );
  //     // console.log("cheek4")

  //     // if (!(await connection.getAccountInfo(associatedTokenTo))) {
  //     //   instructions.push(createAssociatedTokenAccount(
  //     //     publicKey,
  //     //     associatedTokenTo,
  //     //     recipientAddress,
  //     //     tokenPRESALE
  //     //   ))
  //     // }

  //     // console.log("cheek5")

  //     // const transferInstruction = createTransferInstruction(
  //     //   fromAccount.address,
  //     //   associatedTokenTo,
  //     //   anchorWallet.publicKey,
  //     //   new BN(10),
  //     //   [],
  //     //   TOKEN_PROGRAM_ID,
  //     // );
  //     // instructions.push(transferInstruction);

  //     // console.log("cheek6")

  //     // const transaction = new Transaction().add(...instructions);

  //     // const { blockhash } = await connection.getRecentBlockhash();
  //     // transaction.recentBlockhash = blockhash;
  //     // transaction.feePayer = anchorWallet.publicKey;

  //     // const signedTransaction = await anchorWallet.signTransaction(transaction);

  //     // const txid = await connection.sendRawTransaction(signedTransaction.serialize());

  //     // await connection.confirmTransaction(txid);

  //     // const tokenMintAddress = new PublicKey('SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt') // SRM tokenMintAddress
  //     // const fs = require('fs');

  //     // // Need sender wallet's private keys to be used as signer
  //     // const senderWallet = Keypair.fromSecretKey(Uint8Array.from(JSON.parse(fs.readFileSync(secret))));
  //     // console.log(senderWallet,"senderWallet")


  //     // // get or create associated tokenAccount's (SRM) for the receiver and sender
  //     // const receiverTokenAccount = await getOrCreateAssociatedTokenAccount(
  //     //   connection,
  //     //   senderWallet,
  //     //   tokenPRESALE, // new token account creation costs some amount of SOL and sender will bear the costs
  //     //   recipientAddress
  //     // );  

  //     // console.log(receiverTokenAccount,"receiverTokenAccount")

  //     // const senderTokenAccount = await getOrCreateAssociatedTokenAccount(
  //     //   connection,
  //     //   senderWallet,
  //     //   tokenMintAddress,
  //     //   senderWallet.publicKey
  //     // );

  //     // // Finally do the transfer
  //     // await transfer(
  //     //   connection,
  //     //   senderWallet,
  //     //   senderTokenAccount.address,
  //     //   receiverTokenAccount.address,
  //     //   senderWallet.publicKey,
  //     //   amount * 1000000, // amount of tokens to transfer, need to multiply the decimals (SRM has 6 decimals)
  //     // )


  //   } catch (error) {
  //     console.log(error)
  //   }
  // };


  const airdropTokens = async (recipientAddresses, amount) => {
    try {
      setLoader(true);
      if (!recipientAddresses || recipientAddresses.length === 0 || !amount) {
        setLoader(false);
        return toast.error("Please provide recipient addresses and amount");
      }

      if (!publicKey) {
        setLoader(false);
        return toast.error("Wallet not connected");
      }

      const instructions = [];

      // Get or create the associated token account for the admin
      const adminTokenAccount = await getOrCreateAssociatedTokenAccount(
        connection,
        publicKey,
        new PublicKey(tokenPRESALE),
        publicKey,
        signTransaction
      );

      for (let i = 0; i < recipientAddresses.length; i++) {
        const recipientPublicKey = new PublicKey(recipientAddresses[i]);

        // // Get or create the associated token account for the recipient
        // const recipientTokenAccount = await getOrCreateAssociatedTokenAccount(
        //   connection,
        //   publicKey,
        //   new PublicKey(tokenPRESALE),
        //   recipientPublicKey,
        //   signTransaction
        // );
        try {
          const adminTokenAccount = await getOrCreateAssociatedTokenAccount(
            connection,
            publicKey,
            new PublicKey(tokenPRESALE),
            recipientPublicKey,
            signTransaction
          );

          if (adminTokenAccount.address) return setLoader(false), toast.error(`Account Already Airdroped: ${recipientPublicKey?.toBase58()?.slice(0, 6)}...${recipientPublicKey?.toBase58()?.slice(-6)}`);

        } catch (error) {


          const associatedAccount = await getAssociatedTokenAddress(tokenPRESALE, recipientPublicKey)
          // const createToken = new Transaction().add(
          const approve = createAssociatedTokenAccountInstruction(
            publicKey,
            associatedAccount,
            recipientPublicKey,
            tokenPRESALE
          )

          instructions.push(approve);

          console.log(associatedAccount?.toBase58(), "associatedAccountassociatedAccount")

          // Create the transfer instruction
          const adjustedAmount = amount * Math.pow(10, 9);
          const transferInstruction = createTransferInstruction(
            adminTokenAccount.address, // The source account
            associatedAccount, // The destination account
            publicKey, // The owner of the source account
            new BN(adjustedAmount), // The amount to transfer
            [], // Multisig signers (if any)
            TOKEN_PROGRAM_ID // The token program ID
          );

          instructions.push(transferInstruction);
        }

        const transaction = new Transaction().add(...instructions);

        // Send the transaction
        const signature = await sendTransaction(transaction, connection);

        // Confirm the transaction
        const response = await connection.confirmTransaction(signature, 'processed');
        console.log('response', response);

        toast.success("Airdrop successful!");
        setLoader(false);
      }

    } catch (error) {
      setLoader(false);
      console.error("Airdrop failed", error);
      toast.error("Airdrop failed");
    }
  };

  useEffect(() => {
    // console.log(publicKey?.toBase58(), "publicKeypublicKeypublicKey")
    const provider = getProvider();

    const connectHandler = (publicKey) => {
      if (publicKey) {
        getUserDataValue();
        getAdminSectionValues();
        getIsUser();
      }
      toast.success("Wallet Connected");
    };

    const disconnectHandler = () => {
      setPubKey(null);
      setConnected(false);
      setLoader()
      setIsUser()
      setIsInitiliseStatePDA()
      setIsInitiliseTokenPDA()
      setTotalsoldTokens()
      setTotalRaisedSol()
      setTokenPrice()
      setConnected()
      setPubKey()
      toast.success("Wallet Disconnected");
    };

    const accountChangedHandler = async (publicKey) => {
      // console.log("change")
      if (publicKey) {
        // console.log("changed")
        getUserDataValue();
        getAdminSectionValues();
        getIsUser();
        // console.log("changed Done")
      }
    };
    if (publicKey) {
      provider?.on("disconnect", disconnectHandler);
      provider?.on("connect", connectHandler);
      provider?.on("accountChanged", accountChangedHandler);
    }
    return () => {
      provider?.removeListener("connect", connectHandler);
      provider?.removeListener("disconnect", disconnectHandler);
      provider?.removeListener("accountChanged", accountChangedHandler);
    };
  }, [publicKey]);

  // useEffect(() => {
  //   const main = async () => {
  //     if (user && publicKey) {
  //       const [userAccount, _2] = PublicKey.findProgramAddressSync(
  //         [
  //           Buffer.from("PRESALE_PAJAMA_USER"), anchorWallet.publicKey.toBuffer()
  //         ],
  //         program.programId
  //       );

  //       let userdata = await program.account.userdata.fetch(userAccount);
  //       console.log("User Data Public Key (Buyer address):", userdata?.userAddress?.toBase58());
  //       console.log("User Purchased Amount :", userdata?.purchaseAmount?.toNumber() / 1e9);
  //     }
  //   }
  //   main()
  // }, [publicKey])

  return (
    <Store.Provider
      value={{
        publicKey,
        // handlePayment,
        tokenPrice,
        getUserDataValue,
        isInitiliseStatePDA,
        airdropTokens,
        participateUser,
        getIsUser,
        buyWithSol,
        toatlSoldTokens,
        totalRaisedSol,
        loader,
        user,
        isPresaleStart,
        depositPresaleTokens,
        initializeATA,
        createPreSaleStart,
        saleStop,
        isInitiliseTokenPDA,
        purchasedToken,
        saleStart,
        getAdminSectionValues
      }}
    >
      {children}
    </Store.Provider>
  );
};
