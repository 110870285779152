import { Route, Routes } from "react-router-dom";
import { LandingPage } from "./screen";
import { Header, Footer } from "./Components/index";


//solana
import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import {
  WalletModalProvider,
  WalletMultiButton,
} from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';
import '@solana/wallet-adapter-react-ui/styles.css';
import { StoreProvider } from "./Phantom/PhantomWallet";
// CSS IMPORT
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import { useMemo } from "react";
import AdminPage from "./screen/adminSection/AdminPage";

function App() {

  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  const network = WalletAdapterNetwork.Devnet;
  // You can also provide a custom RPC endpoint.
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  const wallets = useMemo(
    () => [new PhantomWalletAdapter()],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [network]
  );

  return (
    <div className="App">
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            <StoreProvider>
          
              <Routes>
                <Route path="/*" element={<LandingPage />} />
                <Route path="/" element={<LandingPage />} />
                <Route path="/admin" element={<AdminPage />} />
              </Routes>
   
            </StoreProvider>
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </div>
  );
}

export default App;
